.modes{
 /* margin-top: 10%; */
 padding-top: 2%;
 padding-left: 7%;
 padding-right: 7%;
 

}


@media (max-height:660px) {
  .modes{
    
    padding: 5%;
   }
  
  
}
.card{
  background-color:white;
  /* margin-left: 9%; */
  /* margin-right: 9%; */
  padding: 0%;
  border-radius: 12px;
  /* margin: 3%; */
  
}
.card-body{
  padding: 3px !important;
  
}
.btn{
  display: flex;
  --bs-btn-border-color: #710100;
  --bs-btn-hover-bg:#E34B00;
    --bs-btn-hover-border-color: #E34B00;
    --bs-btn-bg: black;
    --bs-btn-active-bg:  #fb722d;
    padding: 1%;
    border-radius: 10px;

}
.modeicon1{
  height: 24px;
  width: 28px;
  background-color:white;
  border-radius: 50%;
  color: #710100;
  margin-left:11%; 
}
.btn{
  background-color: #710100;
  width: 100%;
  
  
}
.card-title{
  font-size: 14px;
  margin: 10px;
}
.m1{
  font-size: 13px;
  font-weight: bolder;
  /* margin-bottom: 8px; */
  
}
@media (max-height:790px) {
  .card-title{
    font-size: 10px;
    margin: 7px;
  }
  .card-body{
    
  }
}
@media (min-width:1300px) {
  .card-title{
    font-size: 12px;
    margin: 7px;
  }
}
.card-title a.btn-link {
  text-decoration: none; 
  color: black; 
}


.card-title a.btn-link:hover {
  color:black;
}
@media (min-width:768px) {
  .card{
    margin: 4%;
  }
}
@media (max-height:540px) {
 .modes{
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 0%;
 }
 .card{
  margin: 2%;
}
.card-title{
  font-size: 8px;
  margin: 5px;
}
.btn{
  padding: 0%;
}
.modeicon1{
  height: 20px;
  width: 24px;
}

}
/* @media (max-width:450px){
 
  .card-title{
    font-size: 12px;
    margin: 7px;
  }
  .card{
    margin-left: 3%;
  margin-right: 3%;
  }.modes{
    margin-top:5%;
   }
}

@media (min-width:1300px) {
  .modes{
    margin-top: 4%;
  }
  .card{
    
    margin-left: 20%;
    margin-right: 20%;
    padding: 0%;
    
   
  }.card-title{
    font-size: 17px;
    
  }
  .btn{
   height: 50px;
    
    
  }.modeicon1{
   
    margin-left:66%;
    
  }
  
}
@media (width:280px){
 
  .card-title{
    font-size: 8px;
    margin: 7px;
  }
  
}
@media (max-height:720px){
 
  .card-title{
    font-size: 13px;
    margin: 7px;
  }
  
}
@media (width:1024px) {
  .modes{
    margin-top: 0%;
  }
  .card{
    
    margin-left: 10%;
    margin-right: 10%;
    padding: 0%;
    
   
  }.card-title{
    font-size: 14px;
    
  }
  .btn{
   height: 30px;
    
    
  }.modeicon1{
   
    margin-left:68%;
    
  }
}
@media (max-width:1299px)and (max-height:650px){
 
 .modes{
  margin-top: 5%;
 }
  
} */