body,
ul,
li,
button {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  text-decoration: none;
  font-family: Arial, sans-serif;
}
body {
  text-size-adjust: none;
 
  /* overscroll-behavior-y: contain; */
  touch-action: manipulation;
  user-select: none;
  user-zoom: none;


}

ul {
  padding: 0;
  margin-left: -2rem;
}
li {
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.App {
  text-align: center;
  background-color: #200101 !important;
 
  width: 100%;
  padding-top: 1px;
  background-image: url(./Components/images/logo5.png);
  background-size: 300px;
  background-repeat: no-repeat;
  background-attachment: unset;/*new one */
  background-position: center;
  height: 100vh;
  overflow: hidden; 
  position: fixed;
}
.hee{
  color: white;
  top:10%;
  z-index: 1;
}

.heading {
  font-size: 30px;
  font-weight: bold;
  /* margin-left: 15px; */
  margin-left: 4%;
 
  
  
}
.modename{
  display: flex;
}
.modename1{
  color:wheat;
  margin-right: 4px;

}
.heading {
  display: inline-block; /* Makes the div wrap around its content */
}
.heading2 {
  font-size: 28px;
  font-weight: bold;
  margin-left: 15px;
 
  display: inline-block; /* Makes the div wrap around its content */
  
  margin-bottom:20px;
}
.first{
  color:#990100 ;
  font-weight: bolder;
  
}
.second{
  color:#19202C;
  font-weight: bolder;
}
.mode{
  background-color:transparent !important;
 
}
.orange{
  height: 30px;
  width: 30px;
}
.wheat{
  height: 30px;
  width: 30px;
}


.board {
  background-color: #E34B00;
  border: 1px solid #D15400;
  border-radius: 8px;
  margin: 10px ; 
  text-align: center; 
  position: relative; 
  padding: 15px;
  /* position: fixed; */
  
}
a {
  text-decoration: none;
}

.board a {
  text-decoration: none;
}

.logo {
  display: block;
  height: 65px;
  position:absolute; 
  top: 10px;
  left: 8px;
  
  
  
}
.logo2 {
  display: block;
  height:35px;
  position:absolute; 
  top: 20px;
  right: 8px;   
}
/* 


@keyframes vibrate {
  0% {
    transform: translateX(-2px); 
  }
  100% {
    transform: translateX(2px); 
  }
} */

.current-date {
  
  color: wheat;
  
}
.list-container1{
  visibility: hidden;
}
.speaker-icon {
  
}

.list-container {
  max-height: 69%;
  overflow-y: auto;
  margin-top: 28px;
  text-align: left;
  margin-left: 20px;
  color: #B8887C;
  margin-bottom: 20px;
  padding-right: 10px;
  position: relative;
  top: -30px;

}

.ListView {
  text-align: center;
}
.message {
  background-color: rgba(209, 84, 0, 0.5); 
  padding: 8px;
  border-radius: 8px;
  margin: 4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
  margin-top: 10px;
}
.last-entered-text {
  font-weight: bolder; 
}
.list-item-number {
  font-weight: bolder;
  margin-right: 5px;
  color: #D2BEB3;
}
.list-item-text {
  color: #D2BEB3; 
  font-size: 15px;
  font-weight: bolder;
  text-transform: uppercase;
}
.message-time {
  color: #ab8976;
  margin-left: auto; 
}
.list-container::-webkit-scrollbar {
  width: 6px;
}.list-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}
.list-container::-webkit-scrollbar-track {
  background-color: #f8f8f8;
}
.list-container::-webkit-scrollbar-button {
  display: none;
}
.text-count {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #D2BEB3; 
  background-color: #D15400 ;
  border-radius: 4px;
  margin-left: 150px;
  margin-right: 150px;
}

.count-text {
  color: wheat;
  
  height: 30px;
  font-size: 100px;
}
.count-text1 {
  color:red;
  bottom: 40px;
  height: 30px;
  font-size: 100px;
}

.error-alert {
  background-color:transparent  !important;
  background-size: 2px 2px !important;
  border: transparent !important;
  border-radius: 5px; 
  color: #ff0000 !important;
  font-weight: bold;
  font-size: 12px !important;
  left: 10px !important;
}
.count-text {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: wheat;
  background-color:  transparent;
  border-radius: 4px;
  text-align: center; 
  position: fixed;
  bottom: 55px;
  left: 0;
  right: 0; 
}
.data-entering-space {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1);
}form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
button[type="button"] {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}
button[type="submit"] {
  margin-top: 10px;
}
.input-container {
  position: relative;
}

.error-message {
  position: relative;
  width: 100%; 
  text-align: center; 
  
}

.error-popup {
  position: absolute;
  background-color:white !important; 
  color:red !important;
  padding: 5px; 
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
  z-index: 1 !important;
  width: 370px; 
  top: -110px;
  left: 60%; 
  transform: translateX(-50%); 
  text-align: center; 
  border: white;

}
.error-popup::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -49%; 
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent  transparent;
  
}
.showmiketooltip{
  position: absolute;
  background-color:white !important; 
  color:black !important;
  padding: 5px; 
  border-radius: 5px;
  margin-bottom: -5%;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
  z-index: 1 !important;
  /* bottom: 50px; */
  /* bottom: 13%; */
  font-weight: lighter;
  transform: translateX(0%); 
  text-align: center; 
  /* border: white; */
  /* right: -20%; */
  font-size: 14px;
  margin-left: 50%;
}
.showmiketooltip::before{
  content: "";
  position: absolute;
  top: 100%;
  left: 87%;
  margin-left: 0%; 
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent  transparent;
}
.showtypetool{
  
  background-color:white !important; 
  color:black !important;
  padding: 5px; 
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
  z-index: 1 !important;
  /* bottom: 14%; */
  margin-bottom: -10%;
 font-weight: lighter;
  left: 1%;
  transform: translateX(0%); 
  text-align: center; 
  /* border: white; */
  /* right:10%; */
  font-size: 14px;
 position: absolute;
  /* margin-top: 600px;
  margin-left: 150px; */
  /* margin-top: 132%;
  margin-left: 40%; */
}
  
.showtypetool::before{
  content: "";
  position: absolute;
  top: 100%;
  left: 2%;
  margin-left: 0%; 
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent  transparent;
}

.error-popup:hover .tooltip6 {
  display: block; 
}

.data-entering-space input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
}
.input-container{
  width: 100%;
}
.user-text {
  color: black; /* Replace with your desired user text color */
}

.recognized-text {
  color:white; /* Replace with your desired recognized text color */
}
/* #1:disabled {
  background-color: #e0e0e0; 
  color:white;
} */
.data-entering-space button[type="button"] {
  background-color: #D15400;
  color:#710100;
  border: none;
  border-radius:50%;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}
audio {
  display: none;
}
.tooltip {
  display: inline-block;
  cursor: pointer;
  position: relative; 
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333; 
  color: #fff; 
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltiptext{
  visibility: hidden;
  background-color: #333; 
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  position: absolute;
  font-size: 10px;
  
  top: 80px; 
 right: 0;
 
}
.tooltiptext2{
  visibility: hidden;
  background-color: #333; 
  color: #fff; 
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  position: absolute;
  font-size: 10px;
  bottom: 45px;
  right: 30px;
}
.tooltiptext3{
  visibility: hidden;
  background-color: #333; 
  color: #fff; 
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  position: absolute;
  font-size: 10px;
  left:50px;
}
.tooltiptext4{
  visibility: hidden;
  background-color: #333; 
  color: #fff; 
  text-align: center;
  border-radius: 10px;
  padding: 2px;
  position: absolute;
 bottom: 40px;
 left: 20px;
 font-size: 10px;
 
}

.message-popup {
  position: absolute;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
  border-radius: 4px;
  width: 180px;
  z-index: 999;
  margin-top: -38px;
  color: #200101;
  height: 70px;
  margin-left: -50%;
  /* margin-left: 0px !important; */
  /* left: 100px ; */
}
@media (min-width:1250px) {
  .message-popup{
    margin-left: -57%;
  }
  .tooltiptext{
    visibility: visible;
  }
  .tooltiptext2{
    visibility: visible;
  }
  .tooltiptext3{
    visibility: visible;
  }
  .tooltiptext4{
    visibility: visible;
  }

}
@media (min-width:1000px) {
  .meter{
    margin-top: -3% !important;
  }
  .list-container{
    max-height:58%;
  }
  .showmiketooltip{
    margin-bottom: -4%;
  }
  .showtypetool{
    margin-bottom: -5%;

  }
}

@media (min-width:500px) {
  
  .showmiketooltip{
    margin-left: 85%;
  }
 
}


@media (max-height:799px) {
  .list-container{
    max-height:58%;
  }
}
@media (height:600px) {
  .list-container{
    max-height:56%;
  }
}
@media (max-height:540px) {
  .list-container{
    max-height:55% !important;
  }
  .board{
    padding: 2px;
  }
  .logo{
    height: 45px;
    top: 5px;
  }
  .logo2{
    height:28px;
    top: 15px;
  }
  .first,.second{
    font-size: 1.1rem !important;
  }
  .board{
    height: 60px !important;
  }.meter{
    height: 8px !important;
  }
  input[type="text"] {
    padding: 6px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    
  }

  
  
  
 
}
.message-popup::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 6%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}
.hint{
  background-color:transparent;
  color: wheat;
  font-size: 1rem;
  font-weight: bold;
  z-index: 1;
  /* bottom: 4%; */
  position: absolute;
  margin-top: 2%;
  /* margin: 6px;
  
  border-radius: 4px;
  font-weight: bold; */
}

.hint2{
  background-color:transparent;
  color: wheat;
  font-size: 1rem;
  font-weight: bold;
  z-index: 1;
  /* bottom: 4%; */
  position: absolute;
  margin-top: 2%;
  /* margin: 6px;
  
  border-radius: 4px;
  font-weight: bold; */
  margin-left: 60%;
}
.hint3{
  background-color:transparent;
  color: wheat;
  font-size: 1rem;
  font-weight: bold;
  z-index: 1;
  /* bottom: 4%; */
  position: absolute;
  margin-top: 2%;
  /* margin: 6px;
  
  border-radius: 4px;
  font-weight: bold; */
  margin-left: 120%;
}
.hintt{
  background-color:transparent;
  color: wheat;
  font-size: 1rem;
  font-weight: bold;
  z-index: 1;

  position: absolute;
  margin-top: -14%;
  /* bottom:15%; */
  /* margin: 6px;
  
  border-radius: 4px;
  font-weight: bold; */
}
.moveLeftToRight {
  white-space: nowrap;
  overflow: hidden;
  animation: moveLeftToRight 20s linear infinite; 
  transform: translateX(0%); 
}
.meter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 88%;
  max-width: 88%; 
  background-color:#710100;
  border-radius: 5px;
  overflow: hidden;
  margin-top: -8%;
  margin-left:6%;
  height: 13px;

}
.meter2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 88%;
  max-width: 88%; 
  background-color:#710100;
  border-radius: 5px;
  overflow: hidden;
  margin-top: -2%;
  margin-left:6%;
  height: 13px;

}

.progress {
  background-color:red; /* Fill color */
  height: 30px; /* Set the height as needed */
  width: var(--percentage, 0%); /* Dynamically set the width */
  position: relative;
  transition: width 0.3s;
  color: red; /* Add a smooth transition effect */
}

.number {
  position: absolute;
  left: 10px;
  font-weight: bold;
  color:black;
  z-index: 1;
}
.wrapper {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 80%;
  max-width:80%;
  background-color:transparent;
  border-radius: 5px;
  padding: 1px;

}

.left-side, .right-side {
  flex: 1;
  text-align: center;

 
}
.left-side{
 margin-left:10% ;
 position: relative;
 left: -10%;
  
}
.right-side{
margin-left: 15%;
position: relative;
right: -25%;
}

.n1 {
 
  font-size: 12px !important ; /* Adjust the font size as needed */
  background:transparent;


}
.n2 {
  position: relative;
  right: 0;
  font-size: 12px ; /* Adjust the font size as needed */
  background:transparent;
  
}

.n1-label, .n2-label {
  font-size: 14px !important ; /* Adjust the font size as needed */
}

.total {
  margin-left: auto;
  /* padding: 1px; */
}


@keyframes moveLeftToRight {
  0% {
    transform: translateX(300%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* @media (min-width:1300px) {
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
} */
@media (min-width: 1500px) {
  .data-entering-space {
    width: 100%;
    
    display: flex; 
    justify-content: center; 
  }

  .input-container {
    max-width: 1500px; 
    width: 100%;
    
  }
  .input-field{
    width: 100%;
  }
  .logo {
    display: block;
    height: 65px;
    position:absolute; 
    top: 20px;
    left: 8px;
    
    
    
  }
  .logo2 {
    display: block;
    height:45px;
    position:absolute; 
    top: 30px;
    right: 8px; 
  
    
  }
  .board {
    height:110px;  
  }
  .share-options {
    
    width: 30% !important;
    margin-left: 35% !important;
  }
  .sp{
    margin-left: -150px !important;
  
  }
  .mode{
    margin-right: -100px !important;
  }
  /* .list-container{
    
    height:73% !important;
  } */
  .error-popup{
    left: 15% !important;
    
  }
  /* .message-popup{
    margin-left: -720px !important;
  } */

}
@media (max-width: 1499px) {
  .data-entering-space {
    width: 100%;
    
    display: flex; 
    justify-content: center; 
  }

  .input-container {
    max-width: 1499px; 
    width: 100%;
    
  }
  .input-field{
    width: 100%;
  }

}
@media (min-width: 1237px) {
  .sp{
    margin-left: -150px;
  
  }
  .mode{
    margin-right: -100px;
  }
  .error-popup{
    left: 17%;
    
  }
  .showtypetool{
  
    
    /* right:70%;
    font-size: 16px; */
   
  }
  .showmiketooltip{
   
    /* bottom: 55px;
   
    right: -5%; */
    font-size: 16px;
  }
  .hintt{
    background-color:transparent;
    color: wheat;
    font-size: 1rem;
    font-weight: bold;
    z-index: 1;
  
    position: absolute;
    margin-top: -5%;
    /* bottom:15%; */
    /* margin: 6px;
    
    border-radius: 4px;
    font-weight: bold; */
  }

}
/* @media(min-width:450px) and (max-width:900px){
  .message-popup{
    margin-left: -50% !important;
  }
} */

@media (min-width:500px) and (max-width:800px){
  /* .message-popup{
    margin-left: -450px;
  } */
  /* .showtypetool{
    bottom: 13% !important;
  
  } */
  /* .showmiketooltip{
    bottom: 13% !important;
  } */
  
.list-container{
  max-height: 62%;
}
}
@media (min-width:1300px) and (max-width:1400px){
  /* .message-popup{
    margin-left: -650px;
  } */
}
@media (width:768px) and (height:1024px){
  /* .message-popup{
    margin-left: -50px !important;
  } */
}
@media (max-width: 768px) {
  h1 {
    font-size: 20px;
  }
  nav ul {
    flex-direction: column;
  }
  form {
    flex-direction: column;
  }
  input[type="text"] {
    width: 100%;
  }
  li {
    margin: 5px 0;
  }
  /* .message-popup{
    margin-left: -25px ;
  } */

}
@media (max-width: 768px) {
  button[type="button"] {
    padding: 8px 16px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .some-element {
    max-height: 690px;
  }
}
@media (max-width: 768px) {
  .board {
    height:83px;  
    
  }

  .heading {
    font-size: 4px;
    padding-left: 55px;
    padding-right: 55px;
  }
  .heading2 {
    font-size: 2px;
  }

  .first,
  .second {
    font-size: 1.3rem;
    
  }
  .tooltiptext{
  
    background-color: #333; 
    color: #fff; 
    text-align: center;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
    font-size: 10px;
    
    top: 60px; 
   right: 0;
   visibility: hidden;
   
  }
  .tooltiptext2{
    background-color: #333; 
    color: #fff; 
    text-align: center;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
    font-size: 10px;
    bottom: 45px;
    right: 30px;
    visibility: hidden;
  }
  .tooltiptext3{
    background-color: #333; 
    color: #fff; 
    text-align: center;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
    font-size: 10px;
    left:170px;
    visibility: hidden;
  }
  .tooltiptext4{
    background-color: #333; 
    color: #fff; 
    text-align: center;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
   bottom: 35px;
   left: 30px;
   font-size: 10px;
   visibility: hidden;
  }
  /* .message-popup{
    left:10px !important;
  } */
}
@media (min-width:478px) {
  .heading{
    /* margin-top: 15px; */
  }
}

@media (max-width: 768px) {
  .count-text {
    font-size: 12px; 
    bottom: 50px; 
  }
}
@media (max-width: 768px) {
  /* .list-container {
    max-height: calc(100vh - 150px); 
    
  } */
}
@media (max-width: 768px) {
  .board {
    margin: 10px ; 
  }

  

  .first,
  .second {
    font-size: 1.3rem; 
  }

  .count-text {
    font-size: 12px; 
    bottom: 50px; 
  }

  /* .list-container {
    max-height: calc(100vh - 150px);
    
  } */
}
@media (max-width:399px) {
  .list-item-text{
    font-size: 14px !important;
  }
}
@media (max-height:791px) and (min-width:1000px){
  /* .list-container{
  max-height: 440px !important;
} */

.hintt{
  margin-top: -5%;
}
}
/* @media (height:1024px)and(width:768px) {
  .message-popup{
    margin-left: -100px !important;
  }
} */

@media(width:1024px){
  /* .list-container{
    max-height: 350px !important;
  } */
  /* .message-popup{
    margin-left:-450px !important ;
  } */
}
@media(width:1280px){
  /* .list-container{
    max-height: 450px !important;
  } */
  
  /* .message-popup{
    margin-left:-600px !important ;
  } */
}
@media (max-height:660px) {
  /* .list-container{
    max-height: 58% !important;
  } */
  
  
}

.input-container2{
  visibility: hidden !important;
}
.btn3{
  visibility: hidden !important;
}
.data-entering-space2{
  visibility: hidden !important;
  background-color: #200101;
}
.data-entering-space3{
  visibility: hidden;
}
.data-entering-space8{
  
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:transparent;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1);
    visibility: hidden !important;
}
.input-container3{
  visibility: hidden;
}
.count-text2{
  visibility: hidden;
}
.speaker1{
  visibility: hidden;
}
.mode1{
  visibility: hidden; 
}
.modeicon{
  height: 24px;
  width: 28px;
  background-color:transparent;
  border-radius: 4px;
  color: wheat;
} 


.btn5,.btn6,.btn7,.btn8{
 
}




.share-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-gap: -5px; 
  background-color: white; 
  padding: 10px; 
  border-radius: 10px;
  width: 70%;
  margin-left: 17%;
  position: absolute;
  bottom: 350px; 
  transition: transform 0.8s ease-in-out; 
}


.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.card {
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn5,
.btn6,
.btn7,
.btn8 {
 
}


.show-share-options .share-options {
  transform: translateY(-300px); 
}
.today-count{
  top:100px;
  color: wheat;
  font-size: 30px;
}
.input-field::placeholder {
  font-size: 14px; 
  
}
.custom-link{
  color: wheat;
}

.error-popup {
  position: absolute;
  background-color: #f44336; 
  color: white; 
  padding: 10px; 
  border-radius: 5px; 
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  z-index: 1; 
  
}
@media (width:280px) {
  .heading {
    font-size: 20px;
    font-weight: bold;
   
    
  }.first{
   
    font-weight: bold;
  }.second{
    font-weight: bold;
  }
  .meter{
    margin-top: -11% !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
 .App{
  height: 100vh;
  overflow-y: hidden;
 }
}

/* App.css */
.watermark {
  position: absolute;
  pointer-events: none;
  color: #aaa; /* Set the color you want for the watermark */
  transition: opacity 0.3s ease-out; /* Optional: add a transition effect */
  margin-top:12px ;
  margin-left: 40%;
  font-size: 15px;
  
}

input {
  /* Add any additional styling for your input field */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  
}

/* Style the watermark to appear faded when the input is not empty */
input:not(:placeholder-shown) + .watermark {
  opacity: 0;
}

@media (max-width:650px) {
  .watermark {
    position: absolute;
    pointer-events: none;
    color: #aaa; /* Set the color you want for the watermark */
    transition: opacity 0.3s ease-out; /* Optional: add a transition effect */
    margin-top:3px ;
    margin-left: 25%;
    font-size: 13px;
    
  }
}


